.offers_li {
    border: 1px solid;
    border-radius: 10px;
    padding: 13px;
    list-style-type: none;
    background-color: #fae771;
    box-shadow: 2px 2px 5px gray;
}
.offers_ul{
display: flex;
    gap: 15px;


}

.ref_li{
        border: 1px solid gray;
        border-radius: 10px;
        padding: 10px;
        width: 450px;
        background-color: #ffffff;
        margin-top: 5px;
    box-shadow: 2px 2px 5px gray;
    }
.div_ref_buttons{
    display: flex;
    gap: 10px;
}
.ref_button{
    padding: 4px;
    border-radius: 5px;
    border: 1px solid gray;
}