.partner_dashboard {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 89px;
    margin-top: 44px;
    background-color: #2c3e50;
    color: #fff;
    padding: 11px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar li {
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar li.active, .sidebar li:hover {
    background-color: #34495e;
}

.content {
    flex: 1 1;
    background-color: #e6ebf4;
    margin-top: 44px;
    padding: 0 0 0 10px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    height: calc(100% - 44px);
    width: 100%;
    overflow: auto;
    scrollbar-width: thin;
}
